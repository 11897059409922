import gql from 'graphql-tag';

export const createApecQuery = gql`
mutation createApecMutation(
  $name: String!,
  $manual: Boolean,
  ) {
  createApec(
    apec: {
      name: $name,
      manual: $manual
    }
  )
}
`;

export const sampleLocationQuery = gql`
  mutation createSampleLocation (
    $name: String!,
    $latitude: Float!,
    $longitude: Float!,
    $gpsMetadata: String!,
    $sampleLocDatetime: String!,
    $logger: String!,
    $drillingName: String,
    $drillingMethod: String,
    $weatherDescription: String,
    $windDirection: String,
    $windSpeed: String,
    $precipitation: String,
    $temperature: String,
    $workContextComment: String,
    $boreholeComment: String,
    $impacted: Boolean,
  ) {
    createSampleLocation (
      sampleLocation: {
        sampleLocName: $name,
        latitude: $latitude,
        longitude: $longitude,
        gpsMetadata: $gpsMetadata,
        sampleLocDatetime: $sampleLocDatetime,
        logger: $logger,
        drillingName: $drillingName,
        impacted: $impacted,
        drillingMethod: $drillingMethod,
        weatherDescription: $weatherDescription,
        windDirection: $windDirection,
        windSpeed: $windSpeed,
        precipitation: $precipitation,
        temperature: $temperature,
        workContextComment: $workContextComment,
        boreholeComment: $boreholeComment,
      }
    )
  }
`;

export const updateSampleLocationQuery = gql`
  mutation updateSampleLocation(
    $name: String!
    $latitude: Float!
    $longitude: Float!
    $logger: String!
    $drillingName: String
    $drillingMethod: String
    $weatherDescription: String
    $windDirection: String
    $windSpeed: String
    $precipitation: String
    $temperature: String
    $workContextComment: String
    $boreholeComment: String
    $impacted: Boolean
    $id: String
  ) {
    updateSampleLocation(
      sampleLocation: {
        sampleLocName: $name,
        latitude: $latitude,
        longitude: $longitude,
        logger: $logger,
        drillingName: $drillingName,
        drillingMethod: $drillingMethod,
        weatherDescription: $weatherDescription,
        windDirection: $windDirection,
        windSpeed: $windSpeed,
        precipitation: $precipitation,
        temperature: $temperature,
        workContextComment: $workContextComment,
        boreholeComment: $boreholeComment,
        impacted: $impacted,
      }
      id: $id
    )
  }
`;

export const smaSubmissionQuery = gql`
  mutation SmaSubmissionMutation(
    $sampleLocationId: ID
    $clientId: ID
    $projectId: ID
    $siteId: ID
    $apecId: ID
  ) {
    createSmaSubmission(
      submission: {
        sampleLocationId: $sampleLocationId,
        clientId: $clientId,
        projectId: $projectId,
        siteId: $siteId,
        apecId: $apecId,
      }
    )
  }
`;

export const updateSmaSubmissionQuery = gql`
  mutation SmaSubmissionMutation(
    $sampleLocationId: ID
    $clientId: ID
    $projectId: ID
    $siteId: ID
    $id: String
    $apecId: ID
  ) {
    updateSmaSubmission(
      submission: {
        sampleLocationId: $sampleLocationId,
        clientId: $clientId,
        projectId: $projectId,
        siteId: $siteId,
        apecId: $apecId,
      }
      id: $id
    )
  }
`;

export const intervalQuery = gql`
  mutation intervalMutation(
    $smaSubmissionId: ID!,
    $intervalTop: Float!,
    $intervalBottom: Float!,
    $intervalComment: String,
    $plasticity: String,
    $moisture: String,
    $color: String,
    $color1: String,
    $color2: String,
    $color3: String,
    $consistence: String,
    $classSchema: String,
    $fragmentShape: String,
    $fragmentModifier: String,
    $fragmentFragment: String,
    $fragmentAdjective: String,
    $fragmentTexture: String,
    $soilHorizon: String,
    $mottleAbundance: String,
    $mottleSize: String,
    $mottleColour: String,
    $mottleContrast: String,
    $mottleType: String,
    $deposition: String,
    $primaryConstituent: String,
    $primaryProportion: String,
    $secondaryConstituent: String,
    $secondaryProportion: String,
    $tertiaryConstituent: String,
    $tertiaryProportion: String,
    $grading: String,
    $angularity: String,
    $odour: String,
    $primarySoil: String,
    $observation: String,
    $geneticTerms: String,
    $texture: String,
    $structure: String,
    $mottleSelect: String,
    $soilFractures: String,
    $soilFragments: String,
    $soilFragmentsModifier: String,
    $soilDisturbances: String
  ) {
    createInterval(
      interval: {
        smaSubmissionId: $smaSubmissionId,
        intervalTop: $intervalTop,
        intervalBottom: $intervalBottom,
        intervalComment: $intervalComment,
        plasticity: $plasticity,
        moisture: $moisture,
        color: $color,
        color1: $color1,
        color2: $color2,
        color3: $color3,
        consistence: $consistence,
        classSchema: $classSchema,
        fragmentShape: $fragmentShape,
        fragmentModifier: $fragmentModifier,
        fragmentFragment: $fragmentFragment,
        fragmentAdjective: $fragmentAdjective,
        fragmentTexture: $fragmentTexture,
        soilHorizon: $soilHorizon,
        mottleAbundance: $mottleAbundance,
        mottleSize: $mottleSize,
        mottleColor: $mottleColour,
        mottleContrast: $mottleContrast,
        mottleType: $mottleType,
        deposition: $deposition,
        primaryConstituent: $primaryConstituent,
        primaryProportion: $primaryProportion,
        secondaryConstituent: $secondaryConstituent,
        secondaryProportion: $secondaryProportion,
        tertiaryConstituent: $tertiaryConstituent,
        tertiaryProportion: $tertiaryProportion,
        grading: $grading,
        angularity: $angularity,
        odour: $odour,
        primarySoil: $primarySoil,
        observation: $observation,
        geneticTerms: $geneticTerms,
        texture: $texture,
        structure: $structure,
        mottleSelect: $mottleSelect,
        soilFractures: $soilFractures,
        soilFragments: $soilFragments,
        soilFragmentsModifier: $soilFragmentsModifier,
        soilDisturbance: $soilDisturbances
      }
    )
  }
`;

export const updateIntervalQuery = gql`
  mutation intervalMutation(
    $smaSubmissionId: ID!,
    $intervalTop: Float!,
    $intervalBottom: Float!,
    $intervalComment: String,
    $plasticity: String,
    $moisture: String,
    $color: String,
    $color1: String,
    $color2: String,
    $color3: String,
    $consistence: String,
    $classSchema: String,
    $fragmentShape: String,
    $fragmentModifier: String,
    $fragmentFragment: String,
    $fragmentAdjective: String,
    $fragmentTexture: String,
    $soilHorizon: String,
    $mottleAbundance: String,
    $mottleSize: String,
    $mottleColour: String,
    $mottleContrast: String,
    $mottleType: String,
    $deposition: String,
    $primaryConstituent: String,
    $primaryProportion: String,
    $secondaryConstituent: String,
    $secondaryProportion: String,
    $tertiaryConstituent: String,
    $tertiaryProportion: String,
    $grading: String,
    $angularity: String,
    $odour: String,
    $primarySoil: String,
    $observation: String,
    $geneticTerms: String,
    $texture: String,
    $structure: String,
    $mottleSelect: String,
    $soilFractures: String,
    $soilFragments: String,
    $soilFragmentsModifier: String,
    $soilDisturbances: String,
    $id: String
  ) {
    updateInterval(
      interval: {
        smaSubmissionId: $smaSubmissionId,
        intervalTop: $intervalTop,
        intervalBottom: $intervalBottom,
        intervalComment: $intervalComment,
        plasticity: $plasticity,
        moisture: $moisture,
        color: $color,
        color1: $color1,
        color2: $color2,
        color3: $color3,
        consistence: $consistence,
        classSchema: $classSchema,
        fragmentShape: $fragmentShape,
        fragmentModifier: $fragmentModifier,
        fragmentFragment: $fragmentFragment,
        fragmentAdjective: $fragmentAdjective,
        fragmentTexture: $fragmentTexture,
        soilHorizon: $soilHorizon,
        mottleAbundance: $mottleAbundance,
        mottleSize: $mottleSize,
        mottleColor: $mottleColour,
        mottleContrast: $mottleContrast,
        mottleType: $mottleType,
        deposition: $deposition,
        primaryConstituent: $primaryConstituent,
        primaryProportion: $primaryProportion,
        secondaryConstituent: $secondaryConstituent,
        secondaryProportion: $secondaryProportion,
        tertiaryConstituent: $tertiaryConstituent,
        tertiaryProportion: $tertiaryProportion,
        grading: $grading,
        angularity: $angularity,
        odour: $odour,
        primarySoil: $primarySoil,
        observation: $observation,
        geneticTerms: $geneticTerms,
        texture: $texture,
        structure: $structure,
        mottleSelect: $mottleSelect,
        soilFractures: $soilFractures,
        soilFragments: $soilFragments,
        soilFragmentsModifier: $soilFragmentsModifier,
        soilDisturbance: $soilDisturbances
      }
      id: $id
    )
  }
`;

export const deleteIntervalsNotInList = gql`
  mutation (
    $ids: [ID]
    $smaSubmissionId: ID!
  ) {
    deleteIntervalsNotInList(
      ids: $ids,
      smaSubmissionId: $smaSubmissionId,
    )
  }
`;

export const deleteMottlesNotInList = gql`
  mutation (
    $ids: [ID]
    $intervalId: ID!
  ) {
    deleteMottlesNotInList(
      ids: $ids,
      intervalId: $intervalId
    )
  }
`;

export const mottleQuery = gql`
  mutation(
    $abundance: String,
    $size: String,
    $color: String,
    $contrast: String,
    $type: String,
    $intervalId: ID,
  ) {
    createMottle(
      mottle: {
        abundance: $abundance,
        size: $size,
        color: $color,
        contrast: $contrast,
        type: $type,
        intervalId: $intervalId,
      }
    )
  }
`;

export const updateMottleQuery = gql`
  mutation (
    $abundance: String,
    $size: String,
    $color: String,
    $contrast: String,
    $type: String,
    $intervalId: ID,
    $id: String,
  ) {
    updateMottle(
      mottle: {
        abundance: $abundance,
        size: $size,
        color: $color,
        contrast: $contrast,
        type: $type,
        intervalId: $intervalId,
      }
      id: $id
    )
  }
`;

export const createSiteQuery = gql`
  mutation createSiteMutation(
    $projectId: ID,
    $name: String!,
    $manual: Boolean,
    $latitude: Float,
    $longitude: Float,
    $provinceId: Int,
    $wellLicense: String,
    $uwi: String,
    $comments: String,
    ) {
    createSite(
      site: {
        projectId: $projectId
        name: $name,
        siteStatus: "ACTIVE",
        manual: $manual,
        wellLicense: $wellLicense,
        provinceId: $provinceId,
        comments: $comments,
        latitude: $latitude,
        longitude: $longitude,
        uwi: $uwi
      }
    )
  }
`;

export const updateSiteQuery = gql`
  mutation updateSiteMutation(
    $projectId: ID,
    $name: String!,
    $manual: Boolean,
    $latitude: Float,
    $longitude: Float,
    $provinceId: Int,
    $wellLicense: String,
    $uwi: String,
    $comments: String,
    $id: String,
    ) {
    updateSite(
      site: {
        projectId: $projectId
        name: $name,
        siteStatus: ACTIVE,
        manual: $manual,
        wellLicense: $wellLicense,
        provinceId: $provinceId,
        comments: $comments,
        latitude: $latitude,
        longitude: $longitude,
        uwi: $uwi
      }
      id: $id,
    )
  }
`;

export const getApecQuery = gql`
  query getApecQuery ($name: String) {
    getApec (name: $name) {
      id
      name
    }
  }
`;

export const getSiteQuery = gql`
  query (
    $name: String
    $id: ID
  ) {
    getSite (
      name: $name
      id: $id
    ) {
      id
      name
    }
}`;

export const deleteAtCommentsNotInList = gql`
  mutation deleteAtComment(
    $ids: [ID],
    $sampleLocationId: ID!,
  ) {
    deleteAtCommentsNotInList(
      ids: $ids
      sampleLocationId: $sampleLocationId
    )
  }
`;

export const atCommentQuery = gql`
  mutation atCommentMutation(
    $depth: Float!,
    $comment: String!,
    $sampleLocationId: ID,
  ) {
    createAtComment(
      comment: {
        depth: $depth,
        comment: $comment,
        sampleLocationId: $sampleLocationId,
      }
    )
  }
`;

export const updateAtCommentQuery = gql`
  mutation updateAtCommentMutation(
    $depth: Float!,
    $comment: String!,
    $sampleLocationId: ID,
    $id: String,
  ) {
    updateAtComment(
      comment: {
        depth: $depth,
        comment: $comment,
        sampleLocationId: $sampleLocationId,
      }
      id: $id,
    )
  }
`;

export const sampleQuery = gql`
  mutation SampleMutation(
    $sampleLocationId: ID!,
    $labParameterId: ID!,
    $sampleId: String!,
    $dateTime: String!,
    $depth: Float!,
    $hold: Boolean,
    $filtered: Boolean,
    $preserved: Boolean
  ) {
    createSample(
      sample: {
        sampleLocationId: $sampleLocationId,
        labParameterId: $labParameterId,
        sampleId: $sampleId,
        timestamp: $dateTime,
        depth: $depth,
        hold: $hold,
        filtered: $filtered,
        preserved: $preserved,
      }
    )
  }
`;

export const updateSampleQuery = gql`
  mutation SampleMutation(
    $sampleLocationId: ID!,
    $labParameterId: ID!,
    $sampleId: String!,
    $dateTime: String!,
    $depth: Float!,
    $hold: Boolean,
    $filtered: Boolean,
    $preserved: Boolean
    $id: String
  ) {
    updateSample(
      sample: {
        sampleLocationId: $sampleLocationId,
        labParameterId: $labParameterId,
        sampleId: $sampleId,
        timestamp: $dateTime,
        depth: $depth,
        hold: $hold,
        filtered: $filtered,
        preserved: $preserved,
      }
      id: $id,
    )
  }
`;

export const deleteFieldSamplesNotInList = gql`
  mutation deleteFieldSamples(
    $ids: [ID],
    $sampleLocationId: ID!,
  ) {
    deleteFieldSamplesNotInList(
      ids: $ids
      sampleLocationId: $sampleLocationId
    )
  }
`;

export const fieldSampleQuery = gql`
  mutation FieldSampleMutation(
    $sampleid: String!,
    $sampleLocationId: ID!,
    $depthTop: Float!,
    $depthBottom: Float,
    $comment: String,
    $ec: Float,
    $ova: Float,
    $bag: Int,
    $jar: Int,
    $vial: Int,
    $lab: Boolean
  ) {
    createFieldSample(
      sample: {
        sampleId: $sampleid,
        sampleLocationId: $sampleLocationId,
        depthTop: $depthTop,
        depthBottom: $depthBottom,
        comment: $comment,
        ec: $ec,
        ova: $ova,
        bag: $bag,
        jar: $jar,
        vial: $vial,
        lab: $lab
      }
    )
  }
`;

export const updateFieldSampleQuery = gql`
  mutation FieldSampleMutation(
    $sampleid: String!,
    $sampleLocationId: ID!,
    $depthTop: Float!,
    $depthBottom: Float,
    $comment: String,
    $ec: Float,
    $ova: Float,
    $bag: Int,
    $jar: Int,
    $vial: Int,
    $lab: Boolean,
    $id: String,
  ) {
    updateFieldSample(
      sample: {
        sampleId: $sampleid,
        sampleLocationId: $sampleLocationId,
        depthTop: $depthTop,
        depthBottom: $depthBottom,
        comment: $comment,
        ec: $ec,
        ova: $ova,
        bag: $bag,
        jar: $jar,
        vial: $vial,
        lab: $lab
      }
      id: $id,
    )
  }
`;
