import React from 'react';
import PropTypes from 'prop-types';
import { SchemaTypeName } from '../../assets/static/models';
import useFormProvider from '../../hooks/useFormProvider';

function BoreholeDescription(props) {
  const {
    classSchema,
    selectedProject,
  } = props;
  const { getForm } = useFormProvider();
  const formState = getForm();
  const boreholeName = formState.borehole;

  const siteName = formState.siteName || '';
  const sampleLocDatetime = formState.sampleLocDatetime || '';

  return (
    <h4>
      {selectedProject?.name || 'No Project Selected'}
      {' '}
      -
      {siteName}
      {' '}
      -
      {boreholeName}
      {' '}
      -
      {SchemaTypeName[classSchema]}
      {' '}
      {sampleLocDatetime ? new Date(sampleLocDatetime).toLocaleString() : ''}
    </h4>
  );
}

BoreholeDescription.propTypes = {
  classSchema: PropTypes.string,
  selectedProject: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

BoreholeDescription.defaultProps = {
  classSchema: '',
};

export default BoreholeDescription;
