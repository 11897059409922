import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import SubmissionItem from '../SubmissionItem/SubmissionItem';
import SubmissionFilterControls from '../SubmissionFilterControls';
import './PreviousSubmission.css';

function PreviousSubmissions({
  duplicateEntity,
  editEntity,
  subPage,
  readOnly = false,
  fetchPrevSubsAndUpdateState,
  setSortedDirection,
  setCurrent,
  setActiveSearchValue,
  activeSearchValue,
  setSearchValue,
  setSortedBy,
  searchValue,
  sites,
  itemCount,
  pageSize,
  sortedBy,
  sortedDirection,
  current,
  previousSubmissions,
  SubmittedCOCCLicked,
}) {
  useEffect(() => {
    fetchPrevSubsAndUpdateState(activeSearchValue);
  }, []);

  const onSiteAccordionClicked = (event, expanded, siteId) => {
    const open = localStorage.getItem('previousSubmissionsOpenAccordions');
    if (!open && expanded) {
      localStorage.setItem('previousSubmissionsOpenAccordions', siteId);
    } else if (expanded) {
      const newList = `${open},${siteId}`;
      localStorage.setItem('previousSubmissionsOpenAccordions', newList);
    } else if (!expanded) {
      localStorage.setItem('previousSubmissionsOpenAccordions', open.replace(siteId, ''));
    }
  };

  const previousSubmissionsOpenList = localStorage.getItem('previousSubmissionsOpenAccordions') || '';

  const onSetSortDirection = (newSortDirection) => {
    setSortedDirection(newSortDirection);

    // eslint-disable-next-line no-undef
    localStorage.setItem('previousSubmissionsSortedDirection', newSortDirection);
    setSortedDirection(newSortDirection);
    fetchPrevSubsAndUpdateState(activeSearchValue, current, sortedBy, newSortDirection);
  };

  const onSetSortedBy = (newSortedBy) => {
    setSortedBy(newSortedBy);
    // eslint-disable-next-line no-undef
    localStorage.setItem('previousSubmissionsSortedBy', newSortedBy);
    fetchPrevSubsAndUpdateState(activeSearchValue, current, newSortedBy);
  };

  const onSearch = () => {
    setActiveSearchValue(searchValue);
    setCurrent(0);
    // eslint-disable-next-line no-undef
    localStorage.setItem('previousSubmissionsCurrentPage', 0);
    // eslint-disable-next-line no-undef
    localStorage.setItem('previousSubmissionsActiveSearchValue', searchValue);
    setActiveSearchValue(searchValue);
    setCurrent(0);
    fetchPrevSubsAndUpdateState(searchValue);
  };

  const onSetSearch = (newSearchValue) => {
    setSearchValue(newSearchValue);
    // eslint-disable-next-line no-undef
    localStorage.setItem('previousSubmissionsSearchValue', newSearchValue);
  };

  const onPageChange = ({ selected }) => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('previousSubmissionsCurrentPage', selected);
    setCurrent(selected);
    fetchPrevSubsAndUpdateState(activeSearchValue, selected);
  };

  const pageCount = Math.ceil(itemCount / pageSize);
  return (
    <div className="PreviousSubmissions">
      <Row>
        <Col xs={6} span={6}>
          <h3 className="previousSubmissionsHeader">Previous Submissions</h3>
        </Col>
        <SubmissionFilterControls
          sortedBy={sortedBy}
          setSortedBy={onSetSortedBy}
          sortedDirection={sortedDirection}
          setSortedDirection={onSetSortDirection}
          searchValue={searchValue}
          onSearch={onSearch}
          setSearchValue={onSetSearch}
        />
        <Col xs={12} span={6}>
          {itemCount > pageSize && (
          <div className="pagination" style={{ margin: '0', float: 'right' }}>
            <ReactPaginate
              className="paginationgroup"
              onPageChange={onPageChange}
              initialPage={current}
              pageCount={pageCount}
              previousLabel="previous"
              breakLabel="..."
              nextLabel="next"
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          )}
        </Col>
      </Row>
      { sites && (
        <Box>
          {sites.map((site) => (
            <Accordion
              className="site"
              key={site.id}
              onChange={(event, enabled) => onSiteAccordionClicked(event, enabled, site.id)}
              defaultExpanded={previousSubmissionsOpenList.includes(site.id)}
            >
              <AccordionSummary style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              >
                <div style={{ order: 0 }}>{site.name}</div>
                <Button
                  className="labCOCButtonSubmitted"
                  onClick={(event) => {
                    event.stopPropagation();
                    SubmittedCOCCLicked(site);
                  }}
                >
                  Lab COC
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                {previousSubmissions.filter((sub) => sub.site.id === site.id).map((sub) => (
                  <SubmissionItem
                    submission={sub}
                    key={sub.submission.id}
                    duplicateEntity={duplicateEntity}
                    editEntity={editEntity}
                    subPage={subPage}
                    readOnly={readOnly}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </div>
  );
}

PreviousSubmissions.propTypes = {
  duplicateEntity: PropTypes.func.isRequired,
  editEntity: PropTypes.func.isRequired,
  subPage: PropTypes.elementType.isRequired,
  readOnly: PropTypes.bool,
  fetchPrevSubsAndUpdateState: PropTypes.func.isRequired,
  setSortedDirection: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  setActiveSearchValue: PropTypes.func.isRequired,
  activeSearchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setSortedBy: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  sortedBy: PropTypes.string.isRequired,
  sortedDirection: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  previousSubmissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  SubmittedCOCCLicked: PropTypes.func.isRequired,
};

PreviousSubmissions.defaultProps = {
  readOnly: false,
};

export default PreviousSubmissions;
